import {
  ClientType,
  DpopMode,
  SwapTokenType,
  TokenBundleIdp,
  createRetryFetch$,
  createStatelessToken$,
  createUpdateCdn$,
  flog,
  persistIdpTokenBundle,
  tapAsync,
} from '@heimdall/client';
import {
  MonoTypeOperatorFunction,
  OperatorFunction,
  concatWith,
  filter,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { AppConfig, isNextPathValid } from './config.js';
import { clearTransientLocalFields } from './local-fields.js';

export type InteractionState =
  | { state: 'ERROR'; error: Error }
  | { state: 'LOGGED_IN' }
  | { state: 'LOGGED_OUT' }
  | { state: 'REQUIRES_IDP_SELECTION'; availableIdps: string[] }
  | { state: 'STAND_BY'; reason: string };

export const ENVS = ['dev', 'qa', 'ppe', 'prod'] as const;
export type Env = (typeof ENVS)[number];

export type RedirectionStateFields = BaseConfig & {
  tokenEndpoint: string;
};

export type BaseConfig = {
  idpKey: string;
  clientUid: string;
  clientId: string;
  nextPath: string;
};

export type OpenIdConfig = {
  authorization_endpoint: string;
  token_endpoint: string;
  scopes_supported: string[];
  end_session_endpoint?: string;
  frontchannel_logout_supported?: boolean;
  frontchannel_logout_uri?: string;
};

export type TokenSwapOpenIdConfig = OpenIdConfig & {
  idps: Record<string, IdpContext>;
  token_endpoint?: string;
  client_type: ClientType;
  dpop?: DpopMode;
};

export type IdpContext = {
  well_known: string;
  client_id: string;
  scopes_required?: string;
  swap?: SwapTokenType;
};

function createWellKnownUrl(issuerUrl: string, clientUid: string) {
  return `${wellKnownUrlFromIssuer(issuerUrl)}?${clientUid}`;
}

function wellKnownUrlFromIssuer(issuerUrl: string) {
  return `${issuerUrl}/.well-known/openid-configuration`;
}

export function createIssuerConfig$(issuerUrl: string, clientUid: string) {
  return createRetryFetch$<TokenSwapOpenIdConfig>(
    createWellKnownUrl(issuerUrl, clientUid),
    { addHeimdallTrace: true, syncToDateHeader: true }
  ).pipe(flog('Issuer Config'));
}

export function validateNextPath(): MonoTypeOperatorFunction<AppConfig> {
  return (source$) => {
    return source$.pipe(
      tap((config) => {
        if (!isNextPathValid(config.nextPath)) {
          throw new Error(`Invalid next path ${config.nextPath}`);
        }
      })
    );
  };
}

export function finalizeLogin(
  nextPath: string
): OperatorFunction<TokenBundleIdp, InteractionState> {
  return (source$) => {
    return source$.pipe(
      tap(clearTransientLocalFields),
      persistIdpTokenBundle(),
      filter(Boolean),
      relayHeimdallTokenToCdn(),
      map((): InteractionState => {
        document.location.replace(nextPath);
        return { state: 'LOGGED_IN' };
      })
    );
  };
}

function relayHeimdallTokenToCdn(): MonoTypeOperatorFunction<TokenBundleIdp> {
  return (source$) => {
    return source$.pipe(
      tapAsync((bundle) => {
        return createStatelessToken$(bundle).pipe(
          switchMap((tokenSet) => {
            return createUpdateCdn$(tokenSet.access_token).pipe(
              flog('Relay token to CDN'),
              concatWith(of('done ✔'))
            );
          })
        );
      })
    );
  };
}
