import {
  ClientType,
  DpopMode,
  SwapTokenType,
  alignLocalStorageValue,
  getLocalStorageValue,
  removeLocalStorageValue,
} from '@heimdall/client';

type LocalFields = {
  codeVerifier: string;
  redirectUri: string;
  heimdallTokenEndpoint: string;
  dpopMode: DpopMode;
  clientType: ClientType;
  swapTokenType?: SwapTokenType;
  idpEndSessionEndpoint?: string;
  linkOriginKey?: string;
  heimdallTokenLogoutUri?: string;
};

const transientLocalFields = [
  'heimdall:code-verifier',
  'heimdall:heimdall-token-endpoint',
  'heimdall:heimdall-token-logout-uri',
  'heimdall:idp-end-session-endpoint',
  'heimdall:redirect-uri',
  'heimdall:swap-token-type',
  'heimdall:dpop-mode',
  'heimdall:link-origin-key',
  'heimdall:client-type',
] as const;

type TransientField = (typeof transientLocalFields)[number];

export function setLocalFields({
  codeVerifier,
  redirectUri,
  heimdallTokenEndpoint,
  swapTokenType,
  idpEndSessionEndpoint,
  heimdallTokenLogoutUri,
  dpopMode,
  clientType,
  linkOriginKey,
}: LocalFields) {
  alignLocalStorageValue('heimdall:code-verifier', codeVerifier);
  alignLocalStorageValue('heimdall:redirect-uri', redirectUri);
  alignLocalStorageValue('heimdall:swap-token-type', swapTokenType);
  alignLocalStorageValue('heimdall:dpop-mode', dpopMode);
  alignLocalStorageValue('heimdall:client-type', clientType);
  alignLocalStorageValue('heimdall:link-origin-key', linkOriginKey);
  alignLocalStorageValue(
    'heimdall:heimdall-token-endpoint',
    heimdallTokenEndpoint
  );
  alignLocalStorageValue(
    'heimdall:heimdall-token-logout-uri',
    heimdallTokenLogoutUri
  );
  alignLocalStorageValue(
    'heimdall:idp-end-session-endpoint',
    idpEndSessionEndpoint
  );
}

export function getLocalFields() {
  const codeVerifier = getLocalStorageValue('heimdall:code-verifier');
  const redirectUri = getLocalStorageValue('heimdall:redirect-uri');
  const heimdallTokenEndpoint = getLocalStorageValue(
    'heimdall:heimdall-token-endpoint'
  );
  const heimdallTokenLogoutUri = getLocalStorageValue(
    'heimdall:heimdall-token-logout-uri'
  );
  const idpEndSessionEndpoint = getLocalStorageValue(
    'heimdall:idp-end-session-endpoint'
  );

  const swapTokenType = getLocalStorageValue(
    'heimdall:swap-token-type'
  ) as SwapTokenType | null;

  const dpopMode =
    (getLocalStorageValue('heimdall:dpop-mode') as DpopMode) || undefined;

  const clientType =
    (getLocalStorageValue('heimdall:client-type') as ClientType) || undefined;

  const linkOriginKey = getLocalStorageValue('heimdall:link-origin-key');

  return {
    codeVerifier,
    redirectUri,
    heimdallTokenEndpoint,
    heimdallTokenLogoutUri,
    idpEndSessionEndpoint,
    swapTokenType,
    dpopMode,
    clientType,
    linkOriginKey,
  };
}

export function clearTransientLocalFields() {
  transientLocalFields.forEach((key: TransientField) =>
    removeLocalStorageValue<TransientField>(key)
  );
}
