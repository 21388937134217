import { LitElement, html, css, CSSResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import loaderUrl from './../assets/waiting.svg';

@customElement('heimdall-loader')
export class HeimdallLoader extends LitElement {
  @property({ type: String })
  message = 'LSEG Workspace Loading...';

  render() {
    return html`<ef-layout class="waiting-screen">
      <img
        src=${loaderUrl}
        class="loader"
        alt="Loading..."
        aria-hidden="true"
      />
      <span class="waiting-screen-message">${this.message}</span>
    </ef-layout>`;
  }

  static get styles(): CSSResult {
    return css`
      .waiting-screen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
      }

      .loader {
        width: 70px;
        height: auto;
        padding: 10px;
        margin: 0;
      }

      .waiting-screen-message {
        font-size: 14px;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'heimdall-loader': HeimdallLoader;
  }
}
