import { LitElement, html, css, CSSResult } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('login-frame')
export class LoginFrame extends LitElement {
  render() {
    return html`<ef-layout>
      <ef-layout class="frame">
        <ef-layout class="content">
          <slot></slot>
        </ef-layout>
      </ef-layout>
    </ef-layout>`;
  }

  static get styles(): CSSResult {
    return css`
      .frame {
        display: grid;
        place-items: center;
        height: 100vh;
      }

      .content {
        max-width: 400px;
        text-align: center;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'login-frame': LoginFrame;
  }
}
